import {
    DomainExplorerContent,
    ExploreLayout,
} from '@backstage/plugin-explore';
import React from 'react';

export const ExplorePage = () => {
    return (
        React.createElement(ExploreLayout, {
            title: "Explore the Sequence ecosystem"   ,
            subtitle: "Browse our ecosystem"  ,}
        
            , React.createElement(ExploreLayout.Route, { path: "domains", title: "Domains",}
                , React.createElement(DomainExplorerContent, null )
            )
            /* <ExploreLayout.Route path="inner-source" title="InnerSource">
            <InnserSourceExplorerContent/>
            </ExploreLayout.Route> */
        )
    );
};

export const explorePage = React.createElement(ExplorePage, null );
