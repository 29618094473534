import React from 'react';
import { Navigate, Route } from 'react-router';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { orgPlugin } from '@backstage/plugin-org';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage/plugin-tech-radar';
import {
  DefaultTechDocsHome,
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';

import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import { AlertDisplay, OAuthRequestDialog } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { FlatRoutes } from '@backstage/core-app-api';
import { googleAuthApiRef } from '@backstage/core-plugin-api';
import { SignInPage } from '@backstage/core-components';

import { CostInsightsPage } from '@backstage/plugin-cost-insights';
import {
  CatalogGraphPage,
  catalogGraphPlugin,
} from '@backstage/plugin-catalog-graph';

import { explorePlugin } from '@backstage/plugin-explore';
import { ExplorePage } from './components/explore/ExplorePage';


const env = process.env.NODE_ENV;
// import myTheme from './lib/theme';

// Comment this out when in dev to disable auth
const googleProvider = {
  id: 'google-auth-provider',
  title: 'Google',
  message: 'Sign in using Google',
  apiRef: googleAuthApiRef,
};

const makeComponents = () => {
  if(env !== "development"){
    return {
      SignInPage: props => (
        React.createElement(SignInPage, { ...props, auto: true, provider: googleProvider,} )
      )
    }
  }else {
    return {}
  }
}

const app = createApp({
  apis,
  // themes: [
  //   {
  //     id: 'my-theme',
  //     title: 'My Custom Theme',
  //     variant: 'light',
  //     theme: myTheme,
  //   },
  // ],

  // Comment this out when in dev to disable auth
  components: makeComponents(),
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
    bind(catalogGraphPlugin.externalRoutes, {
      catalogEntity: catalogPlugin.routes.catalogEntity,
    });
    bind(explorePlugin.externalRoutes, {
      catalogEntity: catalogPlugin.routes.catalogEntity,
    });
  },
});



const AppProvider = app.getProvider();
const AppRouter = app.getRouter();

const routes = (
  React.createElement(FlatRoutes, null
    , React.createElement(Navigate, { key: "/", to: "catalog",} )
    , React.createElement(Route, { path: "/catalog", element: React.createElement(CatalogIndexPage, null ),} )
    , React.createElement(Route, {
      path: "/catalog/:namespace/:kind/:name",
      element: React.createElement(CatalogEntityPage, null ),}
    
      , entityPage
    )
    , React.createElement(Route, { path: "/docs", element: React.createElement(TechDocsIndexPage, null ),}
      , React.createElement(DefaultTechDocsHome, null )
    )
    , React.createElement(Route, {
      path: "/docs/:namespace/:kind/:name/*",
      element: React.createElement(TechDocsReaderPage, null ),}
    )
    , React.createElement(Route, { path: "/create", element: React.createElement(ScaffolderPage, null ),} )
    , React.createElement(Route, { path: "/api-docs", element: React.createElement(ApiExplorerPage, null ),} )
    , React.createElement(Route, {
      path: "/tech-radar",
      element: React.createElement(TechRadarPage, { width: 1500, height: 800,} ),}
    )
    , React.createElement(Route, { path: "/catalog-import", element: React.createElement(CatalogImportPage, null ),} )
    , React.createElement(Route, { path: "/explore", element: React.createElement(ExplorePage, null ),} )
    , React.createElement(Route, { path: "/search", element: React.createElement(SearchPage, null ),}
      , searchPage
    )
    , React.createElement(Route, { path: "/cost-insights", element: React.createElement(CostInsightsPage, null ),} )
    , React.createElement(Route, { path: "/settings", element: React.createElement(UserSettingsPage, null ),} )
    , React.createElement(Route, {
      path: "/catalog-graph",
      element: 
        React.createElement(CatalogGraphPage, {
          initialState: {
            rootEntityRefs: ['system:sequence-website'],
          },}
        )
      ,}
    ), "…"

  )
);

const App = () => {
  return (
    React.createElement(AppProvider, null
      , React.createElement(AlertDisplay, null )
      , React.createElement(OAuthRequestDialog, null )
      , React.createElement(AppRouter, null
        , React.createElement(Root, null, routes)
      )
    )
  )
};

export default App;
