



































  
  export class CostInsightsClient  {
  
    async getLastCompleteBillingDate() {
      return '2021-01-01'; // YYYY-MM-DD
    }
  
    async getUserGroups(userId) {
      return [];
    }
  
    async getGroupProjects(group) {
      return [];
    }
  
    async getAlerts(group) {
      return [];
    }
  
    async getDailyMetricData(metric, intervals) {
      return {
        id: 'remove-me',
        format: 'number',
        aggregation: [],
        change: {
          ratio: 0,
          amount: 0
        }
      }
    }
  
    async getGroupDailyCost(group, intervals) {
      return {
        id: 'remove-me',
        aggregation: [],
        change: {
          ratio: 0,
          amount: 0
        }
      }
    }
  
    async getProjectDailyCost(project, intervals) {
      return {
        id: 'remove-me',
        aggregation: [],
        change: {
          ratio: 0,
          amount: 0
        }
      }
    }
  
    async getProductInsights(options) {
      return {
        id: 'remove-me',
        aggregation: [0, 0],
        change: {
          ratio: 0,
          amount: 0
        },
        entities: {}
      }
    }
  }