/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 24,
  },
  // path: {
  //   fill: '#7df3e1',
  // },
});
const LogoFull = () => {
  const classes = useStyles();

  return (
    React.createElement('svg', {
      className: classes.svg,
      width: "609",
      height: "96",
      viewBox: "0 0 609 96"   ,
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",}
    
      , React.createElement('path', {
        d: "M450.809 41.7582C450.809 34.372 454.387 30.7944 460.274 30.7944C465.352 30.7944 469.161 34.1412 469.161 41.7582V75.2266H487.282V36.334C487.282 25.1393 480.472 15.7913 466.391 15.7913C459.004 15.7913 453.233 18.5611 449.309 25.0239H449.078L446.77 17.5224H432.688V75.2266H450.809V41.7582Z"                      ,
        fill: "white",}
      )
      , React.createElement('path', {
        d: "M400.986 15.676C384.481 15.676 371.208 27.4477 371.208 45.5668V47.5287C371.208 65.9941 385.289 77.0733 401.679 77.0733C414.837 77.0733 426.725 70.495 429.495 57.223H412.528C410.797 61.6086 406.873 63.2243 402.14 63.2243C394.638 63.2243 390.368 57.9155 389.675 50.7602H429.379C429.495 49.6061 429.61 46.7209 429.61 44.4127C429.61 25.0241 415.76 15.676 400.986 15.676ZM411.605 39.2193H390.021C390.945 33.2181 394.407 28.6018 401.448 28.6018C407.565 28.6018 411.605 32.9873 411.605 39.2193Z"                                               ,
        fill: "white",}
      )
      , React.createElement('path', {
        d: "M333.548 52.376V17.5227H315.427V51.222C315.427 68.4178 325.353 76.958 341.973 76.958C358.594 76.958 368.635 68.5332 368.635 51.222V17.5227H351.091V52.376C351.091 59.8776 346.821 62.532 342.319 62.532C337.934 62.532 333.548 59.8776 333.548 52.376Z"                     ,
        fill: "white",}
      )
      , React.createElement('path', {
        d: "M293.158 70.1486H293.389V96.0001H311.51V17.5224H298.583L296.274 24.2161H296.043C292.235 18.7919 285.887 15.7913 278.153 15.7913C262.456 15.7913 250.684 29.294 250.684 45.5666V47.7594C250.684 65.5323 263.264 76.9577 276.768 76.9577C283.924 76.9577 289.926 74.3033 293.158 70.1486ZM269.728 46.1437C269.728 37.6035 273.537 30.6789 281.731 30.6789C289.926 30.6789 293.735 37.7189 293.735 46.4899C293.735 55.0301 289.926 61.9546 281.731 61.9546C273.537 61.9546 269.728 54.9147 269.728 46.1437Z"                                           ,
        fill: "white",}
      )
      , React.createElement('path', {
        d: "M219.919 15.676C203.414 15.676 190.141 27.4477 190.141 45.5668V47.5287C190.141 65.9941 204.222 77.0733 220.611 77.0733C233.769 77.0733 245.657 70.495 248.427 57.223H231.461C229.73 61.6086 225.805 63.2243 221.073 63.2243C213.571 63.2243 209.3 57.9155 208.608 50.7602H248.312C248.427 49.6061 248.543 46.7209 248.543 44.4127C248.543 25.0241 234.693 15.676 219.919 15.676ZM230.537 39.2193H208.954C209.877 33.2181 213.34 28.6018 220.381 28.6018C226.498 28.6018 230.537 32.9873 230.537 39.2193Z"                                               ,
        fill: "white",}
      )
      , React.createElement('path', {
        d: "M140.466 33.9125V34.8358C140.466 38.8751 142.197 43.0298 144.736 45.5688C150.507 51.3392 159.279 52.0316 165.166 53.4165C170.013 54.5706 171.398 56.8788 171.398 59.5332C171.398 62.9954 168.628 64.7266 164.473 64.7266C159.51 64.7266 156.394 61.9568 156.163 56.4171H139.773V57.6866C140.466 71.7665 151.2 76.9598 164.473 76.9598C176.246 76.9598 189.173 71.5357 189.173 57.9175V56.7634C189.173 52.7241 187.788 48.8002 185.364 46.1458C179.362 40.1446 171.052 39.3367 163.665 37.9518C160.549 37.3748 158.471 35.759 158.471 32.7584C158.471 30.104 160.549 28.0267 164.473 28.0267C168.859 28.0267 171.283 30.3348 171.283 34.1433H187.673V32.8738C187.673 20.756 176.477 15.678 164.127 15.678C153.162 15.678 140.466 20.4097 140.466 33.9125Z"                                                                       ,
        fill: "white",}
      )
      , React.createElement('path', {
        d: "M520.394 30.1021C526.396 30.1021 530.551 33.6797 531.705 40.1426H548.21V38.6423C546.941 23.87 534.245 15.676 519.586 15.676C502.966 15.676 489.462 27.7939 489.462 45.336V47.5287C489.462 65.7633 503.312 77.0733 519.702 77.0733C533.668 77.0733 546.595 68.8793 548.21 54.107V52.6067H531.705C530.205 59.3004 526.742 62.6472 520.394 62.6472C511.853 62.6472 507.814 55.7227 507.814 46.3747C507.814 37.142 511.738 30.1021 520.394 30.1021Z"                                         ,
        fill: "white",}
      )
      , React.createElement('path', {
        d: "M580.011 15.676C563.506 15.676 550.232 27.4477 550.232 45.5668V47.5287C550.232 65.9941 564.314 77.0733 580.703 77.0733C593.861 77.0733 605.749 70.495 608.519 57.223H591.553C589.821 61.6086 585.897 63.2243 581.165 63.2243C573.663 63.2243 569.392 57.9155 568.7 50.7602H608.404C608.519 49.6061 608.635 46.7209 608.635 44.4127C608.635 25.0241 594.784 15.676 580.011 15.676ZM590.629 39.2193H569.046C569.969 33.2181 573.432 28.6018 580.472 28.6018C586.59 28.6018 590.629 32.9873 590.629 39.2193Z"                                               ,
        fill: "white",}
      )
      , React.createElement('circle', { cx: "45.6037", cy: "45.6037", r: "45.6037", fill: "#04000C",} )
      , React.createElement('circle', { cx: "45.6038", cy: "45.6038", r: "6.5735", fill: "#F9EBDA",} )
      , React.createElement('ellipse', {
        cx: "45.6033",
        cy: "72.7193",
        rx: "8.62772",
        ry: "8.62772",
        fill: "#F18824",}
      )
      , React.createElement('ellipse', {
        cx: "45.6033",
        cy: "18.4881",
        rx: "8.62772",
        ry: "8.62772",
        fill: "#534378",}
      )
      , React.createElement('ellipse', {
        cx: "69.0865",
        cy: "59.1614",
        rx: "8.62772",
        ry: "8.62772",
        transform: "rotate(-60 69.0865 59.1614)"  ,
        fill: "#D73F31",}
      )
      , React.createElement('circle', {
        cx: "22.1206",
        cy: "32.0459",
        r: "8.62772",
        transform: "rotate(-60 22.1206 32.0459)"  ,
        fill: "#4292CF",}
      )
      , React.createElement('ellipse', {
        cx: "69.0867",
        cy: "32.0459",
        rx: "8.62772",
        ry: "8.62772",
        transform: "rotate(-120 69.0867 32.0459)"  ,
        fill: "#DC527F",}
      )
      , React.createElement('ellipse', {
        cx: "22.1209",
        cy: "59.1616",
        rx: "8.62772",
        ry: "8.62772",
        transform: "rotate(-120 22.1209 59.1616)"  ,
        fill: "#4E8735",}
      )
    )
  );
};

export default LogoFull;
