/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 28,
  },
  // path: {
  //   fill: '#7df3e1',
  // },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    React.createElement('svg', {
      className: classes.svg,
      width: "613",
      height: "613",
      viewBox: "0 0 613 613"   ,
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",}
    
      , React.createElement('rect', { width: "613", height: "613", fill: "white",} )
      , React.createElement('rect', { width: "613", height: "613", fill: "#F9EBDA",} )
      , React.createElement('circle', { cx: "307", cy: "307", r: "222", fill: "#04000C",} )
      , React.createElement('circle', { cx: "307", cy: "307", r: "32", fill: "#F9EBDA",} )
      , React.createElement('circle', { cx: "307", cy: "439", r: "42", fill: "#F18824",} )
      , React.createElement('circle', { cx: "307", cy: "175", r: "42", fill: "#534378",} )
      , React.createElement('circle', {
        cx: "421.315",
        cy: "373",
        r: "42",
        transform: "rotate(-60 421.315 373)"  ,
        fill: "#D73F31",}
      )
      , React.createElement('circle', {
        cx: "192.685",
        cy: "241",
        r: "42",
        transform: "rotate(-60 192.685 241)"  ,
        fill: "#4292CF",}
      )
      , React.createElement('circle', {
        cx: "421.315",
        cy: "241",
        r: "42",
        transform: "rotate(-120 421.315 241)"  ,
        fill: "#DC527F",}
      )
      , React.createElement('circle', {
        cx: "192.685",
        cy: "373",
        r: "42",
        transform: "rotate(-120 192.685 373)"  ,
        fill: "#4E8735",}
      )
    )
  );
};

export default LogoIcon;
